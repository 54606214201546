<template>
  <a-row>
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1 faq-content">
      <a-tabs
        :active-key="$route.query.tab || 'content'"
        class="main-tabs"
        type="card"
        @change="$router.push({ query: { tab: arguments[0] } })"
      >
        <a-tab-pane key="content">
          <span slot="tab"> <a-icon type="project" />{{ $t("ingradient") }}</span>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" class="form_title">
              <label>{{ $t('SelectSenator') }}</label>
            </a-col>
            <a-col :span="8" class="sm-mb">
              <a-select
                v-model="form.person_id"
                :placeholder="$t('Select')"
                style="width: 100%; padding-right: 8px"
              >
                <a-select-option
                  v-for="item in staffList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <br />
          <!-- Соров -->
          <a-row style="margin-bottom: 15px" align="middle" class="flex-baseline">
            <a-col :span="6" class="form_title"> {{$t('Question')}}: </a-col>
            <a-col :span="12">
              <a-button v-if="!req_files.length >= 1" @click="$refs.documentRef.click()">
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handeDocumentInput('req_files', $event, 'documentRef')"
                />
                <a-icon type="upload" />{{ $t("FileSelect") }}
              </a-button>

              <template v-if="req_files.length != 0">
                <p style="padding-left: 15px">{{ $t('Downloads') }}</p>
                <InputFileItem
                  v-for="item in req_files"
                  :key="item.uid"
                  :file="item.binary_file"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState('req_files', item)"
                />

                <a-row style="border-bottom: 1px solid #ddd; padding-bottom: 15px" />
              </template>
            </a-col>
            <div v-if="!form.req_file" class="error-text">{{ $t('FileSelect') }}.</div>
          </a-row>
          <br />
          <!-- Натижа -->
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" class="form_title"> {{ $t('Result') }}: </a-col>
            <a-col :span="12">
              <a-button
                v-if="!res_files.length >= 1"
                @click="$refs.documentRefResponse.click()"
              >
                <input
                  ref="documentRefResponse"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handeDocumentInput('res_files', $event, 'documentRefResponse')"
                />
                <a-icon type="upload" />{{ $t("FileSelect") }}
              </a-button>

              <template v-if="res_files.length != 0">
                <p style="padding-left: 15px">{{ $t('Downloads') }}</p>
                <InputFileItem
                  v-for="item in res_files"
                  :key="item.uid"
                  :file="item.binary_file"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState('res_files', item)"
                />

                <a-row style="border-bottom: 1px solid #ddd; padding-bottom: 15px" />
              </template>
            </a-col>
            <div v-if="!form.res_file" class="error-text">{{ $t("FileSelect") }}.</div>
          </a-row>
        </a-tab-pane>
      </a-tabs>

      <a-row class="form-buttons__action" type="flex">
        <a-col :span="4" class="form_title" />
        <a-col>
          <a-button :disabled="pending" @click="$router.go(-1)">
            <a-icon type="close" />{{ $t("Cancel") }}
          </a-button>

          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submit"
          >
            <a-icon type="check" />
            {{ form.id ? $t("Save") : "Добавить" }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import InputFileItem from "@/components/utils/input-file-item";
import cloneDeep from "lodash/cloneDeep";
export default {
  data() {
    return {
      langTab: $langPrefix,
      pending: false,
      loading: false,
      form: {
        res_file: null,
        req_file: null,
        person_id: null,
      },
      staffList: [],
      req_files: [],
      res_files: [],
    };
  },
  components: {
    InputFileItem,
  },
  computed: {
    getKey() {
      return this.langTab;
    },
  },
  async created() {
    const { data } = await this.$api.get("/admin/person/list", {
      params: {
        type: 1,
      },
    });
    this.staffList = data.data.results;
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      let ID = this.$route.params.id;
      try {
        let res = await this.$api.get(`/admin/request/${ID}`);
        let data = res.data && res.data.data;
        this.form = data;
        if (data.req_file)
          this.req_files = [
            {
              binary_file: {
                file: data.req_file,
                name: data.req_file && data.req_file.split("/")[7],
              },
              uid: Math.random().toString().slice(2),
            },
          ];
        if (data.res_file)
          this.res_files = [
            {
              binary_file: {
                file: data.res_file,
                name: data.res_file && data.res_file.split("/")[7],
              },
              uid: Math.random().toString().slice(2),
            },
          ];
      } catch (e) {
        console.error(e);
        return e;
      }
    },
    async submit() {
      let ID = this.$route.params.id;
      this.pending = true;
      try {
        let newData = cloneDeep(this.form);
        newData = {
          ...newData,
        };
        let f = new FormData();
        Object.keys(newData).forEach((key) => {
          key != "res_file" && key != "req_file" && f.append(key, newData[key]);
        });
        typeof newData.req_file != "string" &&
          this.req_files &&
          this.req_files.forEach((item) => {
            item &&
              item.binary_file &&
              f.append("req_file", item.binary_file, item.binary_file.name);
          });
        typeof newData.res_file != "string" &&
          this.res_files &&
          this.res_files.forEach((item) => {
            item &&
              item.binary_file &&
              f.append("res_file", item.binary_file, item.binary_file.name);
          });
        const r = await this.$api.put(`/admin/request/${ID}`, f);
        if (r.status == 200 && r.data.status) {
          this.$router.push({ name: "senate-inquiries" });
          this.$message.success("Успешно сохранено");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("Ошибка при сохранении");
        this.pending = false;
      }
      this.pending = false;
    },
    async deleteDocumentFileFromState(key, arg) {
      if (this.form.id) {
        let k = key.split("_")[0];
        let r = await this.$api.delete(`/admin/request/${k}-file/${this.form.id}`);
        if (r.status == 200 && r.data.status) {
          this[key] = this[key].filter((el) => el.uid != arg.uid);
          this.fetchData();
        }
      } else {
        this[key] = this[key].filter((el) => el.uid != arg.uid);
      }
    },
    async handeDocumentInput(key, e, ref) {
      if (e.target.files && e.target.files != 0) {
        Array.from(e.target.files).forEach((item) => {
          this[key] = [
            ...this[key],
            {
              binary_file: item,
              uid: Math.random().toString().slice(2),
            },
          ];
        });
        console.log(this[key], key, e.target.files);
      }
      this.$refs[ref].value = null;
    },
  },
};
</script>
